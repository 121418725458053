import React from "react";
import useDocumentTitle from "../../components/useDocumentTitle";
import FancyFeatureTewentyFour from "../../components/features/FancyFeatureTewentyFour";
import FancyFeatureTewentyTwo from "../../components/features/FancyFeatureTewentyTwo";
import HeaderLandingAppointment from "../../components/header/landing/HeaderLandingAppointment";
import HeroBannerNine from "../../components/hero-banner/HeroBannerNine";

import Footer from "../../components/footer/Footer";
import CopyRightFour from "../../components/footer/CopyRightFour";

const HomeTest = () => {
  useDocumentTitle(
    "Planny HR - Plataforma de Recursos Humanos"
  );
  // For header select menu
  // const [click1, setClick1] = useState(false);
  // const handleClick1 = () => setClick1(!click1);
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <HeaderLandingAppointment />
      {/* End Header Landing Appoinment Scheduling */}

      <HeroBannerNine />
      {/* End Hero Banner Nine */}

      {/* =============================================
            Fancy Feature Twenty Two
        ==============================================  */}
      <div className="fancy-feature-twentyTwo mt-200 md-mt-120">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-md-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-eight text-center mb-40 md-mb-10">
                <h2>
                  Una plataforma para cualquier {" "}
                  <span>
                    negocio <img src="images/shape/191.svg" alt="shape" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentyTwo />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-twentyTwo */}

      {/* =============================================
            Fancy Feature Twenty Three
        ==============================================  */}
      <div className="fancy-feature-twentyThree pt-170 md-pt-100" id="features">
        <div className="container">
          <div
            className="title-style-nine text-center pb-180 md-pb-100"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h6>Características</h6>
            <h2>
              Planny HR es justo lo que necesitas, rápido &{" "}
              <span>
                centralizado <img src="images/shape/192.svg" alt="shape" />
              </span>
            </h2>
            <p>
              Continuamente agregamos nuevas funcionalidades para que estés siempre actualizado.
            </p>
          </div>
          {/* End title */}


        </div>
      </div>

      <div className="container">
          <div className="row align-items-center block-style-two pb-250 md-pb-90">
          <div className="col-lg-7 col-md-7 m-auto">
              <div data-aos="fade-right" data-aos-duration="1200">
                <div className="img-holder img-holder-three">
                  <img src="images/assets/feature-img-07.png" alt="feature" />
                  <img
                    src="images/assets/feature-img-07-01.png"
                    alt="feature"
                    className="cs-screen screen-one"
                  />
                  <img
                    src="images/shape/5.svg"
                    alt="feature"
                    className="cs-screen screen-two"
                  />
                  <img
                    src="images/shape/6.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="text-wrapper"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#FF5291" }}>
                  Rápido y fácil
                </h6>
                <h3 className="font-gilroy-bold">
                  Gestiona la asistencia de tus colaboradores
                </h3>
                <p>
                  Realiza el seguimiento de las asistencias, planifica y modifica fácilmente, con solicitudes y flujos automáticos, tú gestión nunca había sido tan fácil.
                </p>
                {/* <a href="#">Learn about flexibility</a> */}
              </div>
              {/*  /.text-wrapper */}
            </div>
          </div>

          <div className="row align-items-center block-style-two pb-250 md-pb-90">
            <div className="col-lg-6 col-md-8 m-auto order-lg-last">
              <div data-aos="fade-left" data-aos-duration="1200">
                <div className="img-holder img-holder-two">
                  <img src="images/assets/feature-img-06.png" alt="feature" />
                  <img
                    src="images/assets/feature-img-06-01.png"
                    alt="feature"
                    className="cs-screen screen-one"
                  />
                  <img
                    src="images/shape/3.svg"
                    alt="feature"
                    className="cs-screen screen-two"
                  />
                  <img
                    src="images/shape/4.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>
            <div className="col-lg-6 order-lg-first">
              <div
                className="text-wrapper pl-5 pr-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#AA4CFF" }}>
                  Centralizado
                </h6>
                <h3 className="font-gilroy-bold">Una plataforma para todos tus procesos</h3>
                <p>
                  Accede fácilmente a todas las métricas de tus colaboradores, toma decisiones en cualquier momento y en cualquier lugar.
                </p>
                {/* <div className="quote">
                  <blockquote>
                    “Our team really feel great to use the deski app specially
                    their faster load & server speed”
                  </blockquote>
                  <div className="d-flex align-items-center">
                    <img src="images/media/img_01.png" alt="media" />
                    <div className="info-meta">
                      <h5>Micle Duke, Product Manager</h5>
                      <span className="font-rubik">Uber Inc.</span>
                    </div>
                  </div>
                </div> */}
                {/*  /.quote */}
              </div>
              {/*  /.text-wrapper */}
            </div>
          </div>

          <div className="row align-items-center block-style-two pb-170 md-pb-50">


            <div className="col-lg-7 col-md-8 m-auto">
              <div data-aos="fade-right" data-aos-duration="1200">
                <div className="img-holder img-holder-one">
                  <div
                    className="cs-screen progress-line-one"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  ></div>
                  <div
                    className="cs-screen progress-line-two"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  ></div>
                  <img src="images/assets/feature-img-05.png" alt="feature" />
                  <img
                    src="images/shape/1.svg"
                    alt="feature"
                    className="cs-screen screen-one"
                  />
                  <img
                    src="images/shape/2.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="text-wrapper"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#03DDD5" }}>
                  Automatizado
                </h6>
                <h3 className="font-gilroy-bold">
                  Conoce los flujos de autorización
                </h3>
                <p>
                Tus colaboradores podrán solicitar y gestionar todos sus permisos, autoriza o rechaza las solicitudes, distribuye fácilmente la responsabilidad de supervisión en tu empresa.
                </p>
              </div>
              {/* /.text-wrapper */}
            </div>
          </div>
          {/* /.block-style-two */}
      </div>


 {/* 	============================================= 
            Fancy Feature Twenty Four 
        ==============================================  */} 
      <div className="fancy-feature-twentyFour pt-140 md-pt-60" id="apps"> 
        <div className="container"> 
          <div className="title-style-nine text-center"> 
            <h6>Soluciones</h6> 
            <h2> 
              <span> 
                Aplicaciones  <img src="images/shape/194.svg" alt="shape" /> {" "} 
              </span>  
              {" "} para ayudarte a la gestión de tus procesos
            </h2> 
          </div> 
        </div> 
        <div className="bg-wrapper mt-100 md-mt-80"> 
          <a 
            href="#feedback" 
            className="click-scroll-button scroll-target d-flex justify-content-center" 
          > 
            <img src="images/shape/200.svg" alt="shape" /> 
          </a> 
          <div className="container"> 
            <FancyFeatureTewentyFour /> 
          </div> 
          <img 
            src="images/shape/195.svg" 
            alt="shape" 
            className="shapes shape-one" 
          /> 
          <img 
            src="images/shape/196.svg" 
            alt="shape" 
            className="shapes shape-two" 
          /> 
          <img 
            src="images/shape/197.svg" 
            alt="shape" 
            className="shapes shape-three" 
          /> 
          <img 
            src="images/shape/198.svg" 
            alt="shape" 
            className="shapes shape-four" 
          /> 
          <img 
            src="images/shape/199.svg" 
            alt="shape" 
            className="shapes shape-five" 
          /> 
        </div> 
        {/* /.bg-wrapper */} 
      </div> 
      {/* End .fancy feature twenty four */} 
 
      {/* 	=====================================================
				Fancy Short Banner Ten
			===================================================== */}
      {/* <div className="fancy-short-banner-ten mt-170 md-mt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-center">
                <h2>Prueba Snappy gratis</h2>
                <p>
                  Prueba nuestro plan profesional por 14 días y después conserva el control de asistencia completamente gratis por siempre.
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
             <div className="dropdown download-btn">
              <button
                className="dropdown-toggle"
                onClick={handleClick1}
                type="button"
              >
                Get Deski app
              </button>
              <div className={click1 ? "dropdown-menu  show" : "dropdown-menu"}>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/103.svg" alt="icon" />
                  <span>IOS & Android</span>
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/104.svg" alt="icon" />
                  <span>Mac & Windows</span>
                </a>
              </div>
            </div> 
            <div className="info-text">
              No requiere tarjeta de crédito. Cancela en cualquier momento.
            </div>
          </div>
        </div>
        <img
          src="images/shape/210.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/211.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div> */}
      {/*  /.fancy-short-banner-ten */}

      {/*  =====================================================
            Footer Style Eight
        ===================================================== */}
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            <CopyRightFour />
          </div>
        </div>
      </footer>
      {/* /.theme-footer-eight  */}
    </div>
  );
};

export default HomeTest;
