import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const FormAppoint = () => {
  return (
    <div  id="mc_embed_signup">
      <form action="https://snappyht.us5.list-manage.com/subscribe/post?u=611e72d5abe0164619e1bd1a7&amp;id=ba9f860a46" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate >
        <div className="form-group row ">
          <div className="col-12 col-lg-9">
            <FontAwesomeIcon icon={faEnvelope}style={{
                color: "#6563ff", 
                position:'absolute', left: 35, top: 0,
                bottom: 0,
                maxHeight: 50,
                height: 50,
                padding: 0,
                width: "20px"
              }}/>

            <input 
              id="mce-EMAIL"
              type="email" 
              name="EMAIL"
              placeholder="Correo electrónico" 
              className="w-100" 
              />
          </div>
        </div>
        <div className="form-group mt-4 row">
          <div className="col-12 col-lg-4">
            <button type="submit" id="mc-embedded-subscribe" className="d-flex justify-content-center align-items-center w-100 ">
              Regístrate {/* <img src="images/icon/119.svg" alt="icon" /> */}
            </button>
          </div>
          <div className="col-12">
            <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
          </div>
        </div>

        {/* <div className="form-group row ">
          <div className="col-12 col-lg-9">
            <FontAwesomeIcon icon={faEnvelope}style={{
                color: "#6563ff", 
                position:'absolute', left: 35, top: 0,
                bottom: 0,
                'max-height': 50,
                height: 50,
                padding: 0
              }}/>
            <input type="email" placeholder="Correo electrónico" className="w-100" />
          </div>
        </div>
        <div className="form-group mt-4 row">
          <div className="col-12 col-lg-4">
            <button className="d-flex justify-content-center align-items-center w-100 ">
            Regístrate 
            </button>
          </div>
        </div> */}

      </form>
    </div>

  );
};

export default FormAppoint;
